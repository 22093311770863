import { Component } from '@angular/core';

@Component({
  selector: 'app-loader1',
  templateUrl: './loader1.component.html',
  styleUrls: ['./loader1.component.scss']
})
export class Loader1Component {

}
