import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PaginationDetails} from "../../../core/models/response";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

    @Input() paginationDetails: PaginationDetails;
    firstLastButtons: boolean = true;
    maxSize: number = 2;
    currentPage: number = 1;
    perPage: number = 25;
    totalPages: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    setPage(page): void {
        this.router.navigate([],
            {
                relativeTo: this.route,
                queryParams: {page: page},
                queryParamsHandling: 'merge',
            }
        );
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.currentPage = Number(params.get('page')) ?? 1;
            this.perPage = Number(params.get('per_page')) ?? 20;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.totalPages = new Array(this.paginationDetails?.pageCount);
    }

}
