<div class="container-fluid">
  <div class="page-header">
    <div class="row">

      <div class="col-lg-6 text-start start">
          <ol class="breadcrumb float-start">
<!--              <li class="breadcrumb-item"><a [routerLink]="'/dashboard/default'">-->
              <li class="breadcrumb-item"><a [routerLink]="'/'">
                  <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a></li>
              <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{breadcrumbs?.parentBreadcrumb}}</li>
              <li class="breadcrumb-item active" *ngIf="breadcrumbs?.childBreadcrumb">{{breadcrumbs?.childBreadcrumb}}
              </li>
          </ol>
<!--        <app-bookmark></app-bookmark>-->
      </div>

        <div class="col-lg-6">
            <!--        <div class="page-header-left">-->
            <!--          <h3>{{title}}</h3>-->
            <!--        </div>-->
        </div>
    </div>
  </div>
</div>
