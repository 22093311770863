import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

// import { AuthenticationService } from '../services/auth.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {


    // constructor(private authenticationService: AuthenticationService) { }
    constructor(

    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {

                console.log('intercept -> ' + err.status);

                // auto logout if 401 response returned from api
                localStorage.removeItem('currentUser');
                location.reload();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
