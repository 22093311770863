<div class="row">
    <div class="col-12 center">
        <div class="loader-box">
            <div class="loader">
                <div class="line bg-primary "></div>
                <div class="line bg-primary "></div>
                <div class="line bg-primary "></div>
                <div class="line bg-primary "></div>
            </div>
        </div>
    </div>
</div>
