import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: "sample-page",
    loadChildren: () => import("../../components/sample-page/sample-page.module").then((m) => m.SamplePageModule),
  },
  {
    path: "sample-page3",
    loadChildren: () => import("../../components/sample-page3/sample-page3.module").then((m) => m.SamplePage3Module),
  },
  {
    path: '',
    loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule),
  },
  {
    path: '',
    loadChildren: () => import('../../components/company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('../../components/settings/settings.module').then(m => m.SettingsModule),
  },
];
