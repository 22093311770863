import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {environment} from "../../../environments/environment";
import {User} from "../models/user";

@Injectable({providedIn: 'root'})
export class AuthService {

    // public currentUser: Observable<User>;
    // private currentUserSubject: BehaviorSubject<User>;

    constructor(private http: HttpClient) {
        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(<string>localStorage.getItem('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User | null {
        const jsonString = localStorage.getItem('currentUser');
        if (jsonString != null) {
            return JSON.parse(jsonString) as User;
        }
        return null;
    }

    login(email: string, password: string) {
        return this.http.post<any>(environment.apiUrl + 'auth/login', {email: email, password: password})
            .pipe(map(user => {
                if (user) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    // this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    forgotPassword(email: string) {
        return this.http.post<any>(environment.apiUrl + 'auth/forgotPassword', {email});
    }

    checkKey(key: string) {
        return this.http.post<any>(environment.apiUrl + 'auth/checkKey', {key});
    }

    passwordReset(key: string, password: string) {
        return this.http.post<any>(environment.apiUrl + 'auth/passwordReset', {key, password});
    }

    logout() {
        this.http.post<any>(environment.apiUrl + 'auth/logout', {}).subscribe();
        localStorage.removeItem('currentUser');
        // this.currentUserSubject.next({} as User);
    }

    me() {


        // let ed: Edition = {
        //     name: "Edycja 2022",
        //     start_date: "2022-10-22",
        //     end_date: "d"
        // } as Edition;
        //
        // this.http.post<any>(environment.apiUrl + 'editions', ed ).subscribe();
        // return this.http.post<any>(environment.apiUrl + 'auth/login', {email, password})
        //     .pipe(map(user => {
        //         if (user && user.token) {
        //             localStorage.setItem('currentUser', JSON.stringify(user));
        //         }
        //         return user;
        //     }));
    }
}
