<div *ngIf="paginationDetails">
<div class="row mt-3 mb-3" *ngIf="paginationDetails.pageCount > 1">
    <div class="col-6">
        <span class="text-muted">Liczba wierszy: {{paginationDetails.total}}</span>
    </div>
    <div class="col-6">
        <nav>
            <ul class="pagination pagination-sm justify-content-end">
                <li
                        [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'"
                        *ngIf="firstLastButtons"
                >
                    <button class="page-link" (click)="setPage(1)"><<</button>
                </li>

                <li [class]="currentPage === 1 ? 'page-item disabled' : 'page-item'">
                    <button class="page-link" (click)="setPage(currentPage-1)"><</button>
                </li>

                <li class="page-item disabled" *ngIf="currentPage > (maxSize + 1)"><span class="page-link">...</span></li>

                <ng-container *ngFor="let p of totalPages; index as i">
                    <li
                            *ngIf="i + 1 >= (currentPage - maxSize) && i + 1 <= (currentPage + maxSize)"
                            [class]="currentPage === (i  + 1) ? 'page-item active' : 'page-item'"
                    >
                        <button class="page-link" (click)="setPage(i + 1)">{{i + 1}}</button>
                    </li>
                </ng-container>

                <li class="page-item disabled" *ngIf="paginationDetails.pageCount > (currentPage + maxSize)"><span class="page-link">...</span></li>

                <li [class]="currentPage === paginationDetails.pageCount ? 'page-item disabled' : 'page-item'">
                    <button class="page-link" (click)="setPage(currentPage+1)">></button>
                </li>

                <li
                        [class]="currentPage === paginationDetails.pageCount ? 'page-item disabled' : 'page-item'"
                        *ngIf="firstLastButtons"
                >
                    <button class="page-link" (click)="setPage(paginationDetails.pageCount)">>></button>
                </li>
            </ul>
        </nav>

    </div>
</div>
</div>


