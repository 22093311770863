import {Component, Input, OnInit} from '@angular/core';
import {CompanyTypeEnum} from "../../../core/enums/company-type-enum";
import {Const} from "../../../app.config";

@Component({
  selector: 'app-badge-company-type',
  templateUrl: './badge-company-type.component.html',
  styleUrls: ['./badge-company-type.component.scss']
})
export class BadgeCompanyTypeComponent implements OnInit{

  @Input() type: any;
  @Input() rate5: any;
  CompanyTypeEnum = CompanyTypeEnum;
  Const = Const;
  text: string;

  constructor() {
  }

  ngOnInit() {
    this.text = Const.DICTIONARY_COMPANY_TYPE[this.type].name;
  }

}
