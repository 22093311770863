import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpLoaderFactory, SharedModule} from "./shared/shared.module";
import {AppRoutingModule} from "./app-routing.module";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {AppComponent} from "./app.component";
import {ToastrModule} from "ngx-toastr";
import {DecimalPipe} from "@angular/common";
import {CookieService} from "ngx-cookie-service";
import "hammerjs";
import "mousetrap";
import {ErrorInterceptor} from "./core/helpers/error.interceptor";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // NoopAnimationsModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    // exports: [
    //     FormsModule,
    //     ReactiveFormsModule
    // ],
    providers: [
        CookieService,
        DecimalPipe,
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
    bootstrap: [AppComponent],
    exports: [
    ]
})
export class AppModule {
}
