import {Component, Input, OnInit} from '@angular/core';
import {RoleEnum} from "../../../core/enums/role-enum";
import {User} from "../../../core/models/user";
import {Const} from "../../../app.config";

@Component({
    selector: 'app-badge-role',
    templateUrl: './badge-role.component.html',
    styleUrls: ['./badge-role.component.scss']
})
export class BadgeRoleComponent implements OnInit{

    @Input() role: any;
    @Input() short: boolean = false;
    RoleEnum = RoleEnum;
    text: string;

    constructor() {
    }

    ngOnInit() {
        this.text = this.short ?
            Const.DICTIONARY_ROLE[this.role].shortName :
            Const.DICTIONARY_ROLE[this.role].name;
    }
}
