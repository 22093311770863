import {RoleEnum} from './core/enums/role-enum';
import {CompanyTypeEnum} from "./core/enums/company-type-enum";
import {CompanyStatusEnum} from "./core/enums/company-status-enum";

export namespace Const {

    export const PLUS_RATE = 8;

    export const DICTIONARY_ROLE = {
        [RoleEnum.ADMIN]: {
            name: 'Administrator',
            shortName: 'Admin',
        },
        [RoleEnum.PM]: {
            name: 'Project Manager',
            shortName: 'PM',
        }
    };

    export const DICTIONARY_COMPANY_TYPE = {
        [CompanyTypeEnum.NORMAL]: {
            name: 'Standardowy',
            shortName: '',
        },
        [CompanyTypeEnum.PREFERRED]: {
            name: 'Zarejestrowany',
            shortName: '',
        },
        [CompanyTypeEnum.PREFERRED_PLUS]: {
            name: 'Preferowany',
            shortName: '',
        }
    };

    export const DICTIONARY_COMPANY_STATUS = {
        [CompanyStatusEnum.ACTIVE]: {
            name: 'Aktywny',
            shortName: 'tak',
        },
        [CompanyStatusEnum.ARCHIVAL]: {
            name: 'Archiwalny',
            shortName: 'nie',
        }
    };

    export const DICTIONARY_PROVINCE = {
        [1]: {
            name: 'dolnośląskie'
        },
        [2]: {
            name: 'kujawsko-pomorskie'
        },
        [3]: {
            name: 'lubelskie'
        },
        [4]: {
            name: 'lubuskie'
        },
        [5]: {
            name: 'łódzkie'
        },
        [6]: {
            name: 'małopolskie'
        },
        [7]: {
            name: 'mazowieckie'
        },
        [8]: {
            name: 'opolskie'
        },
        [9]: {
            name: 'podkarpackie'
        },
        [10]: {
            name: 'podlaskie'
        },
        [11]: {
            name: 'pomorskie'
        },
        [12]: {
            name: 'śląskie'
        },
        [13]: {
            name: 'świętokrzyskie'
        },
        [14]: {
            name: 'warmińsko-mazurskie'
        },
        [15]: {
            name: 'wielkopolskie'
        },
        [16]: {
            name: 'zachodniopomorskie'
        }
    };


    export const DICTIONARY_RATE = {
        [1]: {
            name: 'Jakość pracy',
            shortName: '',
        },
        [2]: {
            name: 'Jakość materiałów',
            shortName: '',
        },
        [3]: {
            name: 'Terminowść',
            shortName: '',
        },
        [4]: {
            name: 'Atrakcyjność cenowa',
            shortName: '',
        },
        [5]: {
            name: 'Komunikacja',
            shortName: '',
        },
    };

}
