<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{ open: navServices.collapseSidebar }" id="page-main-header">
    <div class="main-header-right row">
        <div class="main-header-left col-auto p-0 d-lg-none">
            <div class="logo-wrapper">
                <!--        <a href="javascript:void(0)"><img src="assets/images/endless-logo.png" alt="" /></a>-->
            </div>
        </div>
        <div class="mobile-sidebar col-auto p-0">
            <div class="media-body text-start switch-sm">
                <label class="switch">
                    <a href="javascript:void(0)" (click)="collapseSidebar()">
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{ open: openNav }">
                <!--        <li>-->
                <!--          <form class="form-inline search-form">-->
                <!--            <div class="form-group mb-0">-->
                <!--              <input class="form-control-plaintext" [class.open]="isOpenMobile" [(ngModel)]="text" [ngModelOptions]="{ standalone: true }" (keyup)="searchTerm(text)" type="text" autocomplete="off" placeholder="Search.." />-->
                <!--              <span class="d-sm-none mobile-search">-->
                <!--                <app-feather-icons [icon]="'search'" (click)="isOpenMobile = !isOpenMobile"></app-feather-icons>-->
                <!--              </span>-->
                <!--              <div class="Typeahead-menu" [class.is-open]="searchResult" *ngIf="menuItems?.length" id="search-outer">-->
                <!--                <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice : 0 : 8">-->
                <!--                  <div class="ProfileCard-avatar">-->
                <!--                    <app-feather-icons [icon]="menuItem.icon" class="header-search"></app-feather-icons>-->
                <!--                  </div>-->
                <!--                  <div class="ProfileCard-details">-->
                <!--                    <div class="ProfileCard-realName">-->
                <!--                      <a [routerLink]="menuItem?.path" class="realname" (click)="removeFix()">{{ menuItem?.title }}</a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--              <div class="Typeahead-menu" [class.is-open]="searchResultEmpty">-->
                <!--                <div class="tt-dataset tt-dataset-0">-->
                <!--                  <div class="EmptyMessage">Opps!! There are no result found.</div>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--          </form>-->
                <!--        </li>-->
                <li></li>
                <li>
                    <a href="javascript:void(0)" class="text-dark" (click)="toggleFullScreen()">
                        <app-feather-icons [icon]="'maximize'"></app-feather-icons>
                    </a>
                </li>
                <!--        <li class="onhover-dropdown">-->
                <!--          <a class="txt-dark" href="javascript:void(0)">-->
                <!--            <h6>EN</h6>-->
                <!--          </a>-->
                <!--          <ul class="language-dropdown onhover-show-div p-20">-->
                <!--            <li>-->
                <!--              <a href="javascript:void(0)" data-lng="en" (click)="changeLanguage('en')"><i class="flag-icon flag-icon-is"></i> English</a>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <a href="javascript:void(0)" data-lng="es" (click)="changeLanguage('es')"><i class="flag-icon flag-icon-um"></i> Spanish</a>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <a href="javascript:void(0)" data-lng="pt" (click)="changeLanguage('pt')"><i class="flag-icon flag-icon-uy"></i> Portuguese</a>-->
                <!--            </li>-->
                <!--            <li>-->
                <!--              <a href="javascript:void(0)" data-lng="fr" (click)="changeLanguage('fr')"><i class="flag-icon flag-icon-nz"></i> French</a>-->
                <!--            </li>-->
                <!--          </ul>-->
                <!--        </li>-->
                <li class="onhover-dropdown">
                    <app-feather-icons [icon]="'bell'"></app-feather-icons>
                    <span class="dot"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li>Ostatnie akcje w systmie <span class="badge rounded-pill badge-primary pull-right"></span>
                        </li>
                        <li *ngFor="let change of companyChanges" [routerLink]="['company-view/'+change.company.id]">
                            <p class="mt-0 ms-0">
                                <span>
                                    <app-feather-icons class="shopping-color"
                                        [icon]="'shopping-bag'"></app-feather-icons>
                                </span>
                                {{change.title}}
                                <small class="pull-right">{{change.createdAt | date:'dd.MM.yyyy HH:mm' }}</small>
                            </p>
                            <p class="mb-0 ms-0">{{change.company.name}}</p>
                            <p class="mb-0 ms-0">{{change.user.lastName}} {{change.user.firstName}}</p>
                        </li>
                        <!--            <li class="bg-light txt-dark"><a href="javascript:void(0)">All</a> notification</li>-->
                    </ul>
                </li>
                <li class="onhover-dropdown">
                    <div class="" *ngIf="loggedUser">
                        {{loggedUser?.lastName}} {{loggedUser?.firstName}}<br/>
                        <span class="fs-9 fw-light">{{const.DICTIONARY_ROLE[loggedUser.role].name}}</span>
                    </div>
                </li>
                <li class="pe-3"><a href="javascript:void(0)" (click)="SignOut()">
                    <app-feather-icons [icon]="'log-out'"></app-feather-icons>
                </a></li>

            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>
<!-- Page Header Ends -->
