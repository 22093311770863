import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Company, CompanyRequestParams} from "../models/company";
import {Observable} from "rxjs";
import {ResponseList} from "../models/response";
import {Note} from "../models/note";
import {CompanyChange} from "../models/company-change";

@Injectable({
  providedIn: 'root'
})
export class CompanyChangeService {

  private url = environment.apiUrl + 'companyChanges';

  constructor(
      private http: HttpClient
  ) {
  }

  getList( limit: number = 10): Observable<any> {
    const httpParams = new HttpParams()
        .set('limit', String(limit))
    return this.http.get<CompanyChange[]>(this.url, {params: httpParams});
  }
}
