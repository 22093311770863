import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Category} from "../models/category";

@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    private url = environment.apiUrl + 'categories';

    constructor(
        private http: HttpClient
    ) {
    }

    getList(): Observable<any> {
        return this.http.get<Category[]>(this.url);
    }

    save(category: Partial<Category>): Observable<any> {
        return this.http.post<any>(this.url, {category});
    }

    deleteCategory(id: number): Observable<any> {
        return this.http.delete<any>(this.url + '/' + id , {});
    }

}
