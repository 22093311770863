<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 footer-copyright">
    </div>

    <div class="col-md-6">
      <p class="pull-right mb-0">Made by kembo.pl</p>
    </div>
  </div>
</div>
