<div class="main-header-left d-none d-lg-block bg-light">
    <div class="logo-wrapper">
        <a href="javascript:void(0)">
            <img src="assets/logo.png" alt="" class="logo mb-1 mt-2"><br>
            <span class="text-uppercase mt-3 text-dark">Baza dostawców</span>
        </a>

    </div>
</div>
<div class="sidebar custom-scrollbar">

    <ul class="sidebar-menu active">
        <li class="active mb-3">
            <a [routerLink]="['companies']" [queryParams]="{orderBy: 'name_asc'}" routerLinkActive="active" class="sidebar-header">
                <app-feather-icons [icon]="'file'"></app-feather-icons>
                Dostawcy
                <i class="fa fa-angle-right pull-right"></i>
            </a>
            <ul class="sidebar-submenu menu-open active">
                <li *ngFor="let item of categories;">
                    <a [routerLink]="['companies']" [queryParams]="{category: item.id, orderBy: 'name_asc'}" routerLinkActive="active">{{item.name}} ({{item.countCompanies}})</a>
                </li>
            </ul>
        </li>
        <li class="active mb-3">
            <a [routerLink]="['users']" [queryParams]="{orderBy: 'name_asc'}" routerLinkActive="active" class="sidebar-header">
                <app-feather-icons [icon]="'users'"></app-feather-icons>
                Użytkownicy
            </a>
        </li>
        <li class="active mb-3" *ngIf="loggedUser?.role == RoleEnum.ADMIN">
            <a [routerLink]="['settings/categories']" routerLinkActive="active" class="sidebar-header">
                <app-feather-icons [icon]="'settings'"></app-feather-icons>
                Kategorie
            </a>
        </li>
    </ul>

</div>
